import React from "react";
import "./App.css";
import { FaWhatsapp, FaInstagram, FaWpforms, FaEdit } from "react-icons/fa";
import image from "./Img/BWW.png";
import imageBlurred from "./Img/BWW_blured.png";

function App() {
  const name = "BilgiWorldWide";
  const alertTxt = "No Forms added yet";
  const notesBtn = "Previous course notes";
  const whatsAppBtn = "WhatsApp";
  const instagramBtn = "Instagram";
  const formsBtn = "Forms";

  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${imageBlurred})`,
          backgroundSize: "cover",
          backgroundSize: "140% 140%",
          backgroundPosition: "center",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="logo">
          <img src={image} alt="" className="avatar" />
          <h1 className="h1">{name}</h1>
          <div>
            <button
              className="buttons"
              onClick={() =>
                (window.location.href =
                  "https://drive.google.com/drive/u/1/folders/16zBYGTQdQUXktU5PXAon8XJsAPR1jDGb")
              }
            >
              <FaEdit className="icons" />
              {notesBtn}
            </button>
          </div>

          <div>
            <button
              className="buttons"
              onClick={() =>
                (window.location.href = `https://chat.whatsapp.com/FRzZ0tDGKTYHtZMuUEgCArs`)
              }
            >
              <FaWhatsapp className="icons" />
              {whatsAppBtn}
            </button>
          </div>

          <div>
            <button
              className="buttons"
              onClick={() =>
                (window.location.href = `https://www.instagram.com/bilgi_worldwide/`)
              }
            >
              <FaInstagram className="icons" />
              {instagramBtn}
            </button>
          </div>

          <div>
            <button
              className="buttons"
              onClick={() => {
                // window.location.href = `https://forms.gle/rQcH2xQHJFL36HYz9`;
                alert(alertTxt);
              }}
            >
              <FaWpforms className="icons" />
              {formsBtn}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
